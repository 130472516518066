$(function() {
    if($('.singleSlider').length > 0)
	{
        $('.singleSlider').owlCarousel({
            items:1,
            //merge:true,
            loop:true,
            margin:0,
            video:true,
            lazyLoad: false,
            autoplay:true,
            smartSpeed: 1000,
            autoplayHoverPause:false,
            center:true			
        })
    };
})
